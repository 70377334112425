// import Swiper JS
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// import Swiper styles
import 'swiper/css';

window.addEventListener('load', ()=> {
    initSlider();
    newsAnimations();
});

window.addEventListener('resize', ()=> {
    initSlider();
})

function initSlider() {
    const wrapperSwiper = document.querySelector('.news-archive-block');

    if (wrapperSwiper) {
        const swiperElement = wrapperSwiper.querySelector('.swiper');
        const buttonPrev = wrapperSwiper.querySelector('.swiper-button-prev');
        const buttonNext = wrapperSwiper.querySelector('.swiper-button-next');
    
        const slider = new Swiper(swiperElement, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 20,
            // observer: true,
            // observeParents: true,
            // centerInsufficientSlides: true,
            // autoHeight: true,
            // centeredSlides: true,

            breakpoints: {
                512: {
                    slidesPerView: 2,
                },
                769: {
                    spaceBetween: 0,
                },
            },
    
            navigation: {
                prevEl: buttonPrev,
                nextEl: buttonNext,
            },

            pagination: {
                el: ".swiper-pagination",
                // clickable: true,
                // dynamicBullets: true,
                // dynamicMainBullets: 3,
            },
        });

        slider;

        if (window.innerWidth > 768) {
            setTimeout(() => {
                slider.destroy();
            }, 200);
        }
    }
}

function newsAnimations() {
    const block = document.querySelector('.news-archive-block');

    if (block) {
        const news = block.querySelectorAll('.swiper-slide.news');

        news.forEach(element => {
            gsap.from(element, {
                scrollTrigger: element,
                bottom: -200,
                // opacity: 0,
                // transitionDelay: "2s",
                // transitionDuration: "2s",
            })
        });
    }
}
